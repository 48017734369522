import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';
import {LessonContentItem} from '../../../data/models/LessonContent/LessonContentList';
import VideoPlayerControls from '../../../uiToolkit/VideoPlayerControls';
import {pauseControlsIcon, playControlsIcon} from '../../../assets/images';
import {
  LessonContentVideo,
  nodeIsASourceNode,
} from '../../../data/models/LessonContent/LessonContentBase';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {truncateDurationTo3Decimals} from '../../../utils/getTimeTextFromSeconds';

const PIP_PLAYED_SECONDS_MARGIN = 3;
const PIP_TICKS_UNTIL_REPORT = 10;

interface Props {
  source: string;
  startTime: number;
  endTime: number;
  onFinished: () => void;
  currentNode?: LessonContentItem;
  started?: boolean;
  showControls?: boolean;
  fitHeight?: boolean;
  onWidthCalculated: (width: number) => void;
  reference?: (ref: any) => void;
  setProgres: (prog: number) => void;
  hideSmallControls?: boolean;
}

const VideoPlayer = React.memo(
  ({
    startTime,
    endTime,
    onFinished,
    currentNode,
    started,
    showControls,
    fitHeight,
    onWidthCalculated,
    reference,
    setProgres,
    hideSmallControls,
  }: Props) => {
    const [newSource, setNewSource] = useState('');
    const [currentSource, setCurrentSource] = useState('');
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [playing, setPlaying] = React.useState(false);
    const [duration, setDuration] = React.useState(-1);
    const [currentNodeId, setCurrentNodeId] = React.useState<string>();
    const [lastStartTime, setLastStartTime] = React.useState(0);
    const [lastEndTime, setLastEndTime] = React.useState(0);

    const [finishedPlaying, setFinishedPlaying] = useState(false);

    const [pipSeekDetected, setPipSeekDetected] = useState(false);
    const [overTheBoundTicks, setOverTheBoundTicks] = useState(0);

    const containerRef = React.useRef<any>(null);
    const playerRef = React.useRef<ReactPlayer>(null);

    React.useEffect(() => {
      if (reference) {
        reference({pause, seekTo: externalSeekTo});
      }
    }, [currentNode, startTime, endTime]);

    React.useEffect(() => {
      if (
        currentNode &&
        nodeIsASourceNode(currentNode.data.ivNodeType) &&
        currentNode?.data.internalId !== currentNodeId &&
        started
      ) {
        if (
          (currentNode.data as LessonContentVideo).contentUrl !== currentSource
        ) {
          setNewSource(
            (currentNode.data as LessonContentVideo).contentUrl || '',
          );
        } else {
          seekTo(startTime);
          setPlaying(true);
          setFinishedPlaying(false);
          setOverTheBoundTicks(0);
          setDuration(playerRef.current?.getInternalPlayer().duration);
        }
        setLastEndTime(endTime);
        setLastStartTime(startTime);
        setCurrentNodeId(currentNode.data.internalId);
      }
    }, [currentNode, started]);

    React.useEffect(() => {
      if (pipSeekDetected) {
        toast.error('Picture In Picture Detected', toastConfig);
      }
    }, [pipSeekDetected]);

    React.useEffect(() => {
      if (finishedPlaying) {
        setPlaying(false);
        setDuration(0);
        onFinished();
      }
    }, [finishedPlaying]);

    //Player controls functions
    const togglePlaying = () => {
      setPlaying(!playing);
    };

    const onProgress = (state: OnProgressProps) => {
      if (duration < 1) return;
      checkForPictureInPicture(state.playedSeconds);
      if (!nodeIsASourceNode(currentNode ? currentNode?.data.ivNodeType : -1)) {
        return;
      }
      let progress = 0;
      let finished = false;
      const playedSeconds = truncateDurationTo3Decimals(state.playedSeconds);
      if (startTime !== undefined && endTime !== undefined) {
        if (playedSeconds < startTime) {
          playerRef.current?.seekTo(startTime, 'seconds');
          progress = startTime;
        } else if (playedSeconds > endTime || playedSeconds >= duration) {
          progress = endTime;
          finished = true;
        } else {
          progress = playedSeconds;
        }
      } else {
        progress = playedSeconds;
      }
      if (finished) {
        setFinishedPlaying(true);
      }
      if (setProgres) {
        setProgres(progress);
      }
    };

    const checkForPictureInPicture = (playedSeconds: number) => {
      if (!showControls && !pipSeekDetected) {
        if (lastStartTime !== undefined && lastEndTime !== undefined) {
          if (
            Math.ceil(playedSeconds) + PIP_PLAYED_SECONDS_MARGIN <
            lastStartTime
          ) {
            setOverTheBoundTicks(ticks => ticks + 1);
            if (overTheBoundTicks > PIP_TICKS_UNTIL_REPORT) {
              setPipSeekDetected(true);
            }
          } else if (
            Math.floor(playedSeconds) - PIP_PLAYED_SECONDS_MARGIN >=
            lastEndTime
          ) {
            setOverTheBoundTicks(ticks => ticks + 1);
            if (overTheBoundTicks > PIP_TICKS_UNTIL_REPORT) {
              setPipSeekDetected(true);
            }
          }
        }
      }
    };

    const onEndedPlaying = () => {
      setFinishedPlaying(true);
    };

    const onDuration = (seconds: number) => {
      const truncatedDuration = truncateDurationTo3Decimals(seconds);
      setDuration(truncatedDuration);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pause = () => {
      setPlaying(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const externalSeekTo = (second: number) => {
      seekTo(second);
    };

    const seekTo = (time: number) => {
      if (time < startTime) {
        playerRef.current?.seekTo(startTime, 'seconds');
      } else if (time > endTime) {
        playerRef.current?.seekTo(endTime, 'seconds');
      } else {
        playerRef.current?.seekTo(time, 'seconds');
      }
    };

    //UI Handlers
    const onReady = (player: ReactPlayer) => {
      if (playerRef && playerRef.current && !fitHeight) {
        const innerPlayer = player.getInternalPlayer();
        innerPlayer.style.borderRadius = '20px';
        innerPlayer.style.backgroundColor = 'transparent';
        innerPlayer.disablePictureInPicture = true;
      }
    };

    React.useEffect(() => {
      window.addEventListener('resize', setPlayerDimentions);
      return () => {
        window.removeEventListener('resize', setPlayerDimentions);
      };
    }, []);

    React.useLayoutEffect(() => {
      setPlayerDimentions();
    }, []);

    const setPlayerDimentions = () => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        onWidthCalculated(containerRef.current.offsetWidth);
        setHeight(containerRef.current.offsetHeight);
      }
    };

    const onNewSourceLoaded = () => {
      setCurrentSource(newSource);
      seekTo(startTime);
      setPlaying(true);
      setFinishedPlaying(false);
    };

    const currentTime = playerRef.current
      ? playerRef.current.getCurrentTime()
      : 0;

    return (
      <div
        className="VideoPlaybackPlayerContainer"
        ref={containerRef}
        style={fitHeight ? {height: '100vh'} : {}}>
        <div className="VideoCover" />
        <ReactPlayer
          url={currentSource}
          playing={playing}
          ref={playerRef}
          width={width}
          height={fitHeight ? height : width / 1.77}
          onReady={onReady}
          onProgress={onProgress}
          onDuration={onDuration}
          progressInterval={1}
          onEnded={onEndedPlaying}
          playsinline
          onError={e => {
            console.log(e);
          }}
          pip={false}
          onPause={() => {
            if (playing && playerRef) {
              playerRef.current?.getInternalPlayer().play();
            }
          }}
        />
        <ReactPlayer
          url={newSource}
          playing={false}
          style={{display: 'none'}}
          onReady={onNewSourceLoaded}
        />
        {showControls && nodeIsASourceNode(currentNode?.data.ivNodeType) ? (
          <VideoPlayerControls
            playing={playing}
            duration={duration}
            togglePlaying={togglePlaying}
            currentTime={currentTime || 0}
            seekTo={seekTo}
            setPlaying={setPlaying}
          />
        ) : !hideSmallControls ? (
          <button className="SmallPlayButton" onClick={togglePlaying}>
            <img
              src={playing ? pauseControlsIcon : playControlsIcon}
              alt="play"
              className="Icon"
            />
          </button>
        ) : null}
      </div>
    );
  },
);

export default VideoPlayer;
