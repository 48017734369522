import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Workspace Dashboard',
  startTrial: 'Start Your Free Trial',
  startTrialInfo:
    "Get started today with a 7-day free trial to explore tapybl's features and create courses at your own pace.",
  startTrialInfo2:
    'Enjoy the flexibility to upgrade or cancel anytime during your trial.',
  startTrialInfo3: 'Ready to elevate your teaching experience?',
  startTrialButton: 'Start Trial',
  contactUsButton: 'Contact Us',
  subcriptionEnded: 'Your Subscription Has Expired',
  subscriptionEndedInfo:
    'Ensure uninterrupted access to your workspace and unlock the complete suite of benefits',
  subscriptionEndedInfo2: 'and features tapybl provides by renewing your plan.',
  clickToSub: 'Simply click on "Renew Now!" to get started.',
  upgradeNow: 'Renew Now!',
  subscriptionExpired:
    'Sorry, it looks like this workspace cannot be accessed right now. Please contact your Administrator.',
  totalLearners: 'Total Learners / Instructors',
  totalCourses: 'Total Published Courses',
  spaceQuota: 'Space Quota',
  aiActionsUsed: 'AI Credits Available',
  buyMoreSpace: 'Expand Storage',
  upgradeForMoreActions: 'Reload Credits',
  upgrade: 'Upgrade Now',
  customizeWorkspaceBranding: 'Customize Workspace Branding',
  manageAccount: 'Manage Account',
  manageIntegrations: 'Manage Integrations',
  quickLinks: 'Quick Links',
  totalAuthors: 'Course Creators',
  totalCreatedCourses: 'Total Created Courses',
  buyMoreUsers: 'Add User Seats',
  getUnlimitedCourses: 'Get Unlimited Courses',
};

const welsh = {
  title: 'Dangosfwrdd y Gweithle',
  startTrial: 'Dechreuwch Eich Prawf Am Ddim',
  startTrialInfo:
    'Dechreuwch heddiw gyda prawf am ddim am 7 diwrnod i archwilio nodweddion tapybl a chreu cyrsiau yn eich cyflymder eich hun',
  startTrialInfo2:
    'Mwynhewch y hyblygrwydd i wella neu ganslo unrhyw bryd yn ystod eich prawf.',
  startTrialInfo3: 'Yn barod i gynyddu eich profiad dysgu?',
  startTrialButton: 'Dechreuwch y Prawf',
  contactUsButton: 'Cysylltwch â Ni',
  subcriptionEnded: 'Mae eich tanysgrifiad wedi dod i ben',
  subscriptionEndedInfo:
    "Sicrhewch fynediad di-dor i'ch gweithle a datgloi'r holl fantais llawn",
  subscriptionEndedInfo2:
    'a nodweddion y mae tapybl yn eu cynnig trwy adnewyddu eich cynllun',
  clickToSub: "Cliciwch ar 'Adnewyddu Nawr!' i ddechrau",
  upgradeNow: 'Adnewyddu Nawr',
  subscriptionExpired:
    "Mae'n ddrwg gennyf, mae'n edrych fel nad oes modd cyrchu'r gweithle hwn ar hyn o bryd. Cysylltwch â'ch Gweinydduwr os gwelwch yn dda.",
  totalLearners: 'Y Dysgwyr Cyfan',
  totalCourses: 'Y Cyrsiau Cyhoeddedig Cyfan',
  spaceQuota: 'Cwota Gofod',
  aiActionsUsed: 'Camau AI a Defnyddiwyd',
  buyMoreSpace: 'Prynu Mwy O Le',
  upgradeForMoreActions: 'Uwchraddio i Gael Mwy o Gamau',
  upgrade: 'Uwchraddio Nawr',
  customizeWorkspaceBranding: "Addasu Brandio'r Gweithle",
  manageAccount: 'Rheoli Cyfrif',
  manageIntegrations: 'Rheoli Integreiddiadau',
  quickLinks: 'Dolenni Cyflym',
  totalAuthors: 'Awduron y Cyrsiau',
  totalCreatedCourses: 'Cyfanswm y Cyrsiau a Grëwyd',
};

export const dashboardText: any = getLocalizationBasedOnLang(english, welsh);
