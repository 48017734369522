import {SUBSCRIPTION_BUISNESS_BASIC, SUBSCRIPTION_CREATOR} from '../config';

export const getTierSummaryConfig = (tier?: number) => {
  switch (tier) {
    case SUBSCRIPTION_CREATOR:
      return creatorTierSummary;
    case SUBSCRIPTION_BUISNESS_BASIC:
      return buisnessTierSummary;
  }
};

const creatorTierSummary = {
  title: 'Creator',
  features1: [
    '1 Workspace',
    'Advanced Interactive Features',
    'Unlimited Courses',
  ],
  features2: ['200,000 AI Credits', '5 GB Storage'],
  price: '€899',
};

const buisnessTierSummary = {
  title: 'Business Basic',
  features1: [
    '1 Workspace',
    'Advanced Interactive Features',
    'Unlimited Courses, Authors and Administrators',
  ],
  features2: ['LTI Support', '400,000 AI Credits', '50 GB Storage'],
  price: '€3.778',
};
