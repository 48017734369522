import React from 'react';
import YesNoOption from '../../../uiToolkit/Inputs/YesNoOption';
import {generateAIVideosText} from '../generateAIVideosText';
import {Form} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';

interface Props {
  aiImage: boolean;
  setAiImage: (value: boolean) => void;
  sliderValue: number;
  setSliderValue: (value: number) => void;
}

const ImageGenerationStep = React.memo(
  ({aiImage, setAiImage, sliderValue, setSliderValue}: Props) => {
    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.target.value);
      if (value < 0) value = 0;
      if (value > 100) value = 100;
      setSliderValue(value);
    };

    return (
      <Col xs={10} lg={6} className="formBox">
        <div>
          <h5>{generateAIVideosText.generateImagesTitle}</h5>
          <YesNoOption value={aiImage} onSelect={setAiImage} />

          {aiImage && (
            <div className="SliderContainer">
              <h5>{generateAIVideosText.sliderHeading}</h5>
              <div className="d-flex flex-row justify-content-between">
                <p className="SliderSide">{generateAIVideosText.sliderLeft}</p>
                <p className="SliderSide">{generateAIVideosText.sliderRight}</p>
              </div>
              <RangeSlider
                value={sliderValue}
                onChange={handleSliderChange}
                tooltipPlacement="top"
                tooltip="auto"
                size="sm"
                disabled={!aiImage}
                min={0}
                max={100}
              />
              <Form.Control
                value={sliderValue}
                onChange={handleSliderChange}
                onKeyPress={event => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (!/^\d+$/.test(keyValue)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          )}
        </div>
      </Col>
    );
  },
);

export default ImageGenerationStep;
