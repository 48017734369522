import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Courses',
  create: 'Create new course',
  actions: 'Actions',
  deleteConfirm: 'Are you sure you want to delete this course?',
  copyConfirm: 'Are you sure you want to copy this course?',
  copySuccess: 'Course copied successfully',
  edit: 'Edit Course Info',
  open: 'Course Editor',
  cantDelete: "You can't delete published course with active enrollments",
  delete: 'Delete Course',
  published: 'Published',
  yes: 'Yes',
  unpublished: 'Unpublished',
  no: 'No',
  public: 'Public',
  private: 'Private',
  copyCourse: 'Create Copy',
  courseDelted: 'Course deleted successfully',
};

const welsh = {
  title: 'Cwrs Newydd',
  subtitle: 'Dechreuwch trwy ychwanegu rhai manylion allweddol am eich cwrs!',
  titleTextPlaceholder: "Rhowch enw i'ch cwrs",
  titleTextTitle: "Enw'r Cwrs",
  subjectSelectTitle: "Categori'r Cwrs",
  subjectSelectPlaceholder: 'Dewiswch gategori ar gyfer y cwrs hwn',
  descriptionTextPlaceholder: 'Disgrifiwch eich cwrs',
  descriptionTextTitle: 'Disgrifiad y Cwrs',
  iconPlaceholder: 'Uwchlwythwch eicon y cwrs',
  saveAndExit: 'Cadw a chau',
  iconHeading: 'Eicon y Cwrs',
  type: "Cwrs Sy'n Efallai i'w Asesadwy",
  publicListing: 'Caniatewch rhestru cyhoeddus gyda chofrestru eich hun',
  structureSelectPlaceholder: 'Dewis strwythur y cwrs',
  structureSelectTitle: "Strwythuro'r cwrs yn unol â",
  sizePlaceholder: 'Dewis rhif',
  nextStep: 'Cam Nesaf',
  cancel: 'Canslo',
  wantGradable: "Ydych chi eisiau cyrsiau sy'n efallai i'w asesadwy?",
  passingScore: "Sgôr i'r Basio",
  copyConfirm: "\"Ydych chi'n siŵr eich bod eisiau copïo'r cwrs hwn!:",
  copySuccess: 'Cwrs wedi’i gopïo’n llwyddiannus',
};

export const coursesListText: any = getLocalizationBasedOnLang(english, welsh);
