import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  ModalHeader: 'AI Video Generator',
  text: 'Generate AI Videos',
  nextStep: 'Next Step',
  skipStep: 'Skip step',
  slideIgnoreAudioHeadingText: 'Slides or Pages to Silence',
  slideIgnoreAudioPlaceholderText:
    'Enter slide or page numbers separated by commas, like 1, 2, 4-6, 8',
  slideIgnoreHeadingText: 'Slides or Pages to Exclude',
  slideIgnorePlaceholderText:
    'Enter slide or page numbers separated by commas, like 1, 2, 4-6, 8',
  textIgnoreHeadingText: 'Exclude Text from Audio',
  textIgnorePlaceholder: 'Enter all phrases to exclude, separated by commas',
  videoDurationTitle: 'Choose Your Video Duration ',
  generateImagesTitle: 'Include AI-Generated Images',
  sliderHeading: 'Content to Image Ratio',
  uploadPPTText: 'Upload your file',
  successMessage:
    'AI Video is being generated. You will be notified once it is ready.',
  uploadPPTSubtitle: 'Accepted formats: pdf, docx, pptx',
  voiceGenderHeading: 'Select the Voice of the Narrator',
  languageDropdownHeading: 'Select the Language of the Narrator',
  dialectDropdownHeading: 'Select the Dialect of the Narrator',
  narrationCustomization: 'Customize Voice Narration',
  slideIgnoreAudioDurationTitle: 'Select the Duration for Slides to Mute',
  slideIgnoreAudioDurationSubtitle:
    'Select how long each muted slide displays to ensure smooth video flow.',
  generateVideo: 'Generate video',
  slideIgnoreAudioDurationWarning:
    'The duration must not exceed a limit of 10 minutes (00:10:00).',
  summaryReady: 'We’re ready to generate your video.',
  summaryInfo:
    'This action cannot be undone. Please note that video generation will take time, depending on the length of your uploaded file and generation choices. You’ll be able to see the final video in your library once it has finished.',
  summary: 'Summary',
  summaryFile: 'File: ',
  summaryExceptions: 'Exceptions: ',
  summaryVideoDuration: 'Video Duration: ',
  summaryMedia: 'AI Media: ',
  summaryNarration: 'Narration: ',
  summaryGenImagesWith: 'Generate images with ',
  summaryRatio: ' ratio',
  summaryNoImages: 'No Images',
  summaryExclude: 'Exclude pages ',
  summaryMute: 'Mute pages ',
  summaryExcludePhrases: 'Exclude phrases ',
  summaryFor: ' for ',
  exit: 'Cancel',
  sliderLeft: 'Your content',
  sliderRight: 'AI Images',
  previousStep: 'Previous Step',
  helpTipFile1: 'Upload one file.',
  helpTipFile2: '25 slides limit for pptx',
  helpTipFile3: '25 page limit for pdf or docx',
  helpTipFile4: 'Larger files will result in longer videos',
  helpTipExclude1:
    'If you have page or slide content, or entire sections you’d like to keep from being shown in your final video, let us know here:',
  helpTipExclude2:
    'A) Slides or Pages to Silence: If you have pages of diagrams or image-only slides you’d like shown visually without any narration, list them here.',
  helpTipExclude3:
    'B) Slides or Pages to Exclude: the slide or page numbers to exclude from your generated video content. They will not appear visually, and their content will not be narrated. Think of this as pages you’d like to delete from your document/presentation.',
  helpTipExclude4:
    ' C) Exclude Text from Audio: any text, such as document authors, headers or footers, to exclude from narration. The reader will skip over them in the generated video.',
  helpTipDuration:
    'Select "Short" for minimal narration, "Average" for standard detail, or "Long" for in-depth commentary on each slide. ',
  helpTipDuration2:
    'If your video contains math equations or formulas, it’s best to select "average" or "long" video length when uploading for the best learner experience.',
  helpTipImageGen1:
    'Note: This section is experimental and can give unexpected results. Proceed with caution. You will be able to remove unwanted images using the timeline editor, but doing so will also remove your slide or document content from that portion of the video.',
  helpTipImageGen2:
    'Move the slider to choose the length of time the images are shown relative to the slide or document content. A choice of 0 would only show your content, while 100 would only show AI images to the viewer.',
  helpTipNarration:
    'Select the narrator’s voice, language, and dialect from the available options for your video.',
  helpTipNarration2:
    'Note: Translations impact the audio of your generated video, and not the visual elements.',
  helpTipSummary:
    'This action costs AI credits. Your credits will be updated after video generation.',
  helpTitle: 'tapybl Tips',
};

const welsh = {
  ModalHeader:
    'Cynhyrchwch fideos o Microsoft PowerPoint, Word neu Adobe PDF gan ddefnyddio ein Cynorthwy-ydd AI.',
  text: 'Cynhyrchwch Fideos AI',
  nextStep: 'Cam Nesaf',
  skipStep: 'Hepgor Cam',
  slideIgnoreAudioHeadingText: "Sleidiau i'w Mudio",
  slideIgnoreAudioPlaceholderText:
    "Nodwch rifau'r sleidiau wedi'u separated â comma, fel 1, 2, 4-6, 8",
  slideIgnoreHeadingText: "Sleidiau i'w Hepgor",
  slideIgnorePlaceholderText:
    "Nodwch rifau sleidiau wedi' u gwahanu gan gomau fel 1, 2, 4-6, 8",
  textIgnoreHeadingText: "Hepgor Testun o'r Sain",
  textIgnorePlaceholder:
    "Nodwch pob ymadrodd i'w hepgor, wedi u gwahanu gan gomau",
  videoDurationTitle: 'Dewiswch Hyd Eich Fideo',
  generateImagesTitle: 'Cynnwys delweddau a gynhyrchwyd gan AI',
  sliderHeading: 'Rhesymau Cynnwys i Delwedd',
  uploadPPTText: 'Mewnosodwch PowerPoint, Word neu PDF',
  successMessage:
    "Mae'r fideo AI yn cael ei gynhyrchu. Byddwch yn cael gwybod pan fydd yn barod",
  uploadPPTSubtitle: '"Y nifer uchaf o sleidiau a ganiateir yw ugain-pum',
  voiceGenderHeading: 'Dewiswch Llais y Naratwr',
  languageDropdownHeading: 'Dewiswch Iaith y  Naratwr',
  dialectDropdownHeading: 'Dewiswch Ddialekt y Naratwr',
  narrationCustomization: "Addasu'r Naratif Llais",
  slideIgnoreAudioDurationTitle: "Dewiswch Hyd y Sleidiau i'w Mudio",
  slideIgnoreAudioDurationSubtitle:
    "Dewiswch pa mor hir y dangosir pob sleid wedi'i fudio i sicrhau llif llyfn y fideo",
  generateVideo: 'Cynhyrchwch fideo',
  slideIgnoreAudioDurationWarning:
    "Nid oes rhaid i'r hyd fod yn fwy na 10 munud (00:10:00).",
};

export const generateAIVideosText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
