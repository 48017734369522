import {getLocalizationBasedOnLang} from './models/User/UserInfo';

const getErrorFromCode = (code: string) => {
  switch (code) {
    case internalErrorCodes.INVALID_EMAIL:
      return getLocalizationBasedOnLang('Invalid Email.', 'E-bost Annilys');
    case internalErrorCodes.INVALID_PASSWORD:
      return getLocalizationBasedOnLang(
        'Invalid Password.',
        'Cyfrinair Annilys',
      );
    case internalErrorCodes.PROVIDE_USERNAME:
      return getLocalizationBasedOnLang(
        'Username is required.',
        'Mae enw defnyddiwr yn ofynnol',
      );
    case internalErrorCodes.PROVIDE_VERIFICATION_CODE:
      return getLocalizationBasedOnLang(
        'Verification code is required.',
        'Mae cod dilysu yn ofynnol',
      );
    case internalErrorCodes.NEED_AT_LEAST_ONE_CORRECT_ANSWER:
      return getLocalizationBasedOnLang(
        'At least one answer should be correct',
        'Dylai o leiaf un ateb fod yn gywir',
      );
    case serverErrorCodes.PASSWORD_TOO_SHORT:
      return getLocalizationBasedOnLang(
        'Password must be at least 8 characters.',
        "Rhaid i'r cyfrinair fod o leiaf 8 nod",
      );
    case serverErrorCodes.PASSWORD_REQUIRES_NON_ALPHANUMERIC:
      return getLocalizationBasedOnLang(
        'Password needs at least one special character.',
        'Mae angen un cymeriad arbennig o leiaf ar y cyfrinair.',
      );
    case serverErrorCodes.PASSWORD_REQUIRED_LOWER:
      return getLocalizationBasedOnLang(
        'Password needs at least one lowercase letter.',
        'Mae angen un llythyren fach o leiaf ar y cyfrinair.',
      );
    case serverErrorCodes.PASSWORD_REQUIRES_UPPER:
      return getLocalizationBasedOnLang(
        'Password needs at least one uppercase letter.',
        'Mae angen un llythyren fawr o leiaf ar y cyfrinair.',
      );
    case serverErrorCodes.PASSWORD_REQUIRES_DIGIT:
      return getLocalizationBasedOnLang(
        'Password needs at least one number.',
        'Mae angen un rhif o leiaf ar y cyfrinair.',
      );
    case serverErrorCodes.EMAIL_FORMAT_NOT_VALID:
      return getLocalizationBasedOnLang(
        'Invalid email format.',
        'Fformat e-bost annilys',
      );
    case serverErrorCodes.EMAIL_ALREADY_TAKEN:
      return getLocalizationBasedOnLang(
        'Email is already taken.',
        "Mae'r e-bost eisoes wedi'i gymryd",
      );
    case serverErrorCodes.USERNAME_ALREADY_TAKEN:
      return getLocalizationBasedOnLang(
        'Username is already taken.',
        "Mae'r enw defnyddiwr eisoes wedi'i gymryd.",
      );
    case serverErrorCodes.INVALID_VERIFICATION_CODE:
      return getLocalizationBasedOnLang(
        'Invalid verification code.',
        'Cod dilysu annilys',
      );
    case serverErrorCodes.INVALID_AUTH_CREDENTIALS:
      return getLocalizationBasedOnLang(
        'Looks like something is wrong with your username or password! Please try again!',
        "Mae'n ymddangos bod rhywbeth yn anghywir gyda'ch enw defnyddiwr neu gyfrinair! Ceisiwch eto os gwelwch yn dda!",
      );
    case serverErrorCodes.ORG_NAME_ALREADY_TAKEN:
      return getLocalizationBasedOnLang(
        'Workspace name is already taken.',
        "Mae enw'r gweithle eisoes wedi'i gymryd.",
      );
    case serverErrorCodes.USER_ALREADY_ORG_OWNER:
      return getLocalizationBasedOnLang(
        'User is already a Workspace owner.',
        "Mae'r defnyddiwr eisoes yn berchennog Gweithle",
      );
    case serverErrorCodes.ORG_ROLE_NAME_TAKEN:
      return getLocalizationBasedOnLang(
        'Workspace role name is already in use.',
        "Mae enw'r rôl Gweithle eisoes yn cael ei ddefnyddio",
      );
    case serverErrorCodes.INVITATION_ALREADY_EXISTS:
      return getLocalizationBasedOnLang(
        'Invitation already exists for this user.',
        'Mae gwahoddiad eisoes yn bodoli ar gyfer y defnyddiwr hwn',
      );
    case serverErrorCodes.USER_ALREADY_IN_ORG:
      return getLocalizationBasedOnLang(
        "Oops! This user is already connected to another workspace and can't be added here. Please try inviting another person or use a different email address.",
        "Wps! Mae'r defnyddiwr hwn eisoes wedi'i gysylltu â gweithle arall ac ni ellir ei ychwanegu yma. Ceisiwch wahodd rhywun arall neu defnyddiwch gyfeiriad e-bost gwahanol",
      );
    case serverErrorCodes.USER_ALREADY_IN_THIS_ORG:
      return getLocalizationBasedOnLang(
        'User is already a member of this Workspace.',
        "Mae'r defnyddiwr eisoes yn aelod o'r Gweithle hwn",
      );
    case serverErrorCodes.USER_ALREADY_A_LEARNER:
      return getLocalizationBasedOnLang(
        'User is already a learner in the other Workspace.',
        "Mae'r defnyddiwr eisoes yn ddysgwr yn y Gweithle arall",
      );
    case serverErrorCodes.MAX_AMOUNT_OF_EMAILS_SENT:
      return getLocalizationBasedOnLang(
        "You've reached the maximum number of retries. Please wait for an hour and try again.",
        'Rydych wedi cyrraedd y nifer uchaf o ail-geisio. Arhoswch am awr a cheisiwch eto',
      );
    case serverErrorCodes.COURSE_NAME_TAKEN:
      return getLocalizationBasedOnLang(
        'Course name taken',
        "Mae enw'r cwrs eisoes wedi'i gymryd",
      );
    case serverErrorCodes.CANT_DELETE_LAST_FOLDER:
      return getLocalizationBasedOnLang(
        'Course should contain at least 1 ',
        "Dylai'r cwrs gynnwys o leiaf 1",
      );
    case serverErrorCodes.FILE_TOO_LARGE:
      return getLocalizationBasedOnLang(
        'File size exceeds 100MB. Please upload a smaller file.',
        'Mae maint y ffeil yn fwy na 100MB. Uwchlwythwch ffeil lai os gwelwch yn dda',
      );
    case serverErrorCodes.INVALID_OPERATION:
      return getLocalizationBasedOnLang(
        'Invalid Operation',
        'Gweithred annilys',
      );
    case serverErrorCodes.CANT_REPUBLISH_COURSE_WITH_ENROLLMENTS:
      return getLocalizationBasedOnLang(
        'This course has active enrollments, please contact your admin to republish the course',
        "Mae gan y cwrs gofrestriadau gweithredol. Cysylltwch â'ch gweinyddwr i aildyfynnu'r cwrs",
      );
    case serverErrorCodes.MAX_FILES_SIZE_EXCEEDED:
      return getLocalizationBasedOnLang(
        'Maximum storage size exceeded',
        "Mae'r maint storio mwyaf wedi'i droseddu",
      );
    case serverErrorCodes.CANT_MAKE_LESSON_NON_GRADABLE:
      return getLocalizationBasedOnLang(
        "This lesson have assessable content and can't be non-assessable",
        'Mae gan y gwers hon gynnwys asesadwy ac ni ellir ei gwneud yn ddwysedd-asesu',
      );
    case internalErrorCodes.CANT_GENERATE_QUESTIONS:
      return getLocalizationBasedOnLang(
        "We couldn't generate questions for your video",
        'Ni allwn gynhyrchu cwestiynau ar gyfer eich fideo',
      );
    case serverErrorCodes.ALREADY_HAVE_A_SUB:
      return getLocalizationBasedOnLang(
        'You already have an active subscription',
        'Mae gennych eisoes danysgrifiad gweithredol',
      );
    case serverErrorCodes.ALREADY_USED_TRIAL:
      return getLocalizationBasedOnLang(
        'You already used trial period',
        "Rydych eisoes wedi defnyddio'r cyfnod prawf",
      );
    case serverErrorCodes.LTI_ISSUER_ALREADY_REGISTERED:
      return getLocalizationBasedOnLang(
        'This LTI Issuer already registered in your workspace',
        "Mae'r LTI Cyhoeddwr hwn eisoes wedi'i gofrestru yn eich Gweithle",
      );
    case serverErrorCodes.MAX_AMOUNT_OF_USERS_REACHED:
      return getLocalizationBasedOnLang(
        'You are trying to invite users over the users quota',
        'Rydych yn ceisio gwahodd defnyddwyr dros y swm defnyddwyr',
      );
    case serverErrorCodes.INCORRECT_USERS_AMOUNT:
      return getLocalizationBasedOnLang(
        'Incorrect users amount',
        'Swm defnyddwyr anghywir',
      );
    case serverErrorCodes.CANT_CHANGE_TO_SAME_TIER:
      return getLocalizationBasedOnLang(
        'You cant change to the same tier',
        "Ni allwch newid i'r un lefel",
      );
    case serverErrorCodes.LESS_USERS_IN_SUB_THAN_YOU_HAVE:
      return getLocalizationBasedOnLang(
        'You are trying to get fewer seats than you have users in the workspace',
        'Rydych yn ceisio cael llai o seddi nag sydd â defnyddwyr yn y Gweithle',
      );
    case serverErrorCodes.MAX_AMOUNT_OF_GENERATIONS_REACHED:
      return getLocalizationBasedOnLang(
        'You have crossed the threshold of AI videos you can generate',
        "Rydych wedi croesi'r trothwy o fideos AI y gallwch eu cynhyrchu",
      );
    case serverErrorCodes.AI_SERVER_OVRLOADED:
      return getLocalizationBasedOnLang(
        "Oops! Couldn't generate the video due to high demand. Please try again later.",
        "Wps! Ni allwyd cynhyrchu'r fideo oherwydd galw uchel. Ceisiwch eto’n ddiweddarach",
      );
    case serverErrorCodes.AI_CANT_READ_FILE:
      return getLocalizationBasedOnLang(
        "Oops! We couldn't process the file. Please make sure it's a PDF or PPTX and isn't corrupted.",
        "Wps! Ni allwyd prosesu'r ffeil. Gwiriwch ei bod yn PDF neu PPTX ac nad yw'n wedi'i llwgr.",
      );
    case serverErrorCodes.AI_TOO_MANY_SLIDES:
      return getLocalizationBasedOnLang(
        'Looks like the file has too many slides. Please keep the slide count at 25 or below.',
        "Mae'n edrych fel bod gan y ffeil ormod o sleidiau. Cadwch y cyfri sleidiau i 25 neu lai.",
      );
    case internalErrorCodes.INVALID_FILE_EXTENSION:
      return getLocalizationBasedOnLang(
        'Video file format is not supported',
        "Fformat ffeil fideo nad yw'n cael ei gefnogi",
      );
    case serverErrorCodes.AI_VIDEO_ALREADY_BEING_GENERATED:
      return getLocalizationBasedOnLang(
        "Oops! You already have a video being processed. Please wait until it's finished before starting another one.",
        'Wps! Mae fideo eisoes yn cael ei brosesu. Arhoswch tan ei gwblhau cyn dechrau un arall',
      );
    case serverErrorCodes.USER_ALREADY_A_LEARNER_IN_THIS_WORKSPACE:
      return getLocalizationBasedOnLang(
        'User already enrolled as a learner in this Workspace',
        "Mae'r defnyddiwr eisoes wedi cofrestru fel dysgwr yn y Gweithle hwn",
      );
    case serverErrorCodes.NO_AUDIO_IN_VIDEO:
      return getLocalizationBasedOnLang(
        "Oops! We can't process this video because it doesn't contain any audio. Please use a video with sound and try again.",
        "Wps! Ni allwn brosesu'r fideo hon oherwydd nad yw'n cynnwys unrhyw sain. Defnyddiwch fideo gyda sain a cheisiwch eto",
      );
    case serverErrorCodes.ALREADY_GENERATING_LESSON:
      return getLocalizationBasedOnLang(
        'You already have a lesson generation in progress. When that completes, you can start your next lesson generation.',
        "Mae genych eisoes gynhyrchu gwers ar fynd. Pan fydd hynny'n gorffen, gallwch ddechrau cynhyrchu gwers nesaf",
      );
    case serverErrorCodes.MAX_AMOUNT_OF_COURSES:
      return getLocalizationBasedOnLang(
        'Trial limit reached. Please visit the subscription settings to upgrade',
        "Cyrhaeddwyd y terfyn treial. Ewch i'r gosodiadau tanysgrifiad i uwchraddio",
      );
    case serverErrorCodes.INVITATION_USER_EXISTS:
      return getLocalizationBasedOnLang(
        'User with this email already exists',
        "Mae defnyddiwr gyda'r e-bost hwn eisoes yn bodoli",
      );
    case serverErrorCodes.NOT_ENOUGH_AI_CREDITS:
      return getLocalizationBasedOnLang(
        "You don't have enough AI credits to perform this action",
        "Nid oes gennych ddigon o gredydau AI i gyflawni'r weithred hon",
      );
    case serverErrorCodes.MAX_AMOUNT_OF_LEARNERS_REACHED:
      return getLocalizationBasedOnLang(
        "You've reached the limit of learners in your current subscription. Upgrade or add more learners through the add-ons shop.",
        'Rydych yn ceisio gwahodd defnyddwyr dros y swm defnyddwyr',
      );
    case serverErrorCodes.MAX_AMOUNT_OF_INSTRUCTORS_REACHED:
      return getLocalizationBasedOnLang(
        "You've reached the limit of instructors in your current subscription. Upgrade or add more instructors through the add-ons shop.",
        'Rydych yn ceisio gwahodd defnyddwyr dros y swm defnyddwyr',
      );
    case serverErrorCodes.MAX_AMOUNT_OF_CREATORS_REACHED:
      return getLocalizationBasedOnLang(
        "You've reached the limit of creators in your current subscription. Upgrade or add more creators through the add-ons shop.",
        'Rydych yn ceisio gwahodd defnyddwyr dros y swm defnyddwyr',
      );
    case serverErrorCodes.STAFF_WAS_REMOVED:
      return 'It appears you no longer have access to this workspace. This could be due to removal or a change in permissions. If you believe this is an error or have any questions, please contact your Workspace Administrator for further assistance.';
    default:
      return getLocalizationBasedOnLang(
        'Oops! Looks like there was an issue - try again later or if this continues to happen reach out to our support team!',
        "Wps! Mae'n edrych fel bod problem - ceisiwch eto’n ddiweddarach neu os yw hyn yn parhau, cysylltwch â'n tîm cymorth!",
      );
  }
};

export const serverErrorCodes = {
  PASSWORD_TOO_SHORT: 'PasswordTooShort',
  PASSWORD_REQUIRES_NON_ALPHANUMERIC: 'PasswordRequiresNonAlphanumeric',
  PASSWORD_REQUIRED_LOWER: 'PasswordRequiresLower',
  PASSWORD_REQUIRES_UPPER: 'PasswordRequiresUpper',
  PASSWORD_REQUIRES_DIGIT: 'PasswordRequiresDigit',
  EMAIL_FORMAT_NOT_VALID: 'email_format_not_valid',
  EMAIL_ALREADY_TAKEN: 'user_with_this_email_already_exists',
  USERNAME_ALREADY_TAKEN: 'DuplicateUserName',
  INVALID_VERIFICATION_CODE: 'InvalidToken',
  INVALID_AUTH_CREDENTIALS: 'invalid_auth_credentials',
  USER_ALREADY_ORG_OWNER: 'user_already_org_owner',
  ORG_NAME_ALREADY_TAKEN: 'org_name_already_taken',
  ORG_ROLE_NAME_TAKEN: 'org_role_name_already_taken',
  INVITATION_ALREADY_EXISTS: 'invitation_already_exists',
  USER_ALREADY_IN_ORG: 'user_already_a_part_of_the_workspace',
  USER_ALREADY_IN_THIS_ORG: 'user_already_a_part_of_this_workspace',
  USER_ALREADY_A_LEARNER: 'user_already_a_learner_in_the_workspace',
  MAX_AMOUNT_OF_EMAILS_SENT: 'max_amount_of_emails_sent',
  COURSE_NAME_TAKEN: 'course_name_taken',
  CANT_DELETE_LAST_FOLDER: 'cant_delete_last_folder',
  FILE_TOO_LARGE: 'file_too_large',
  INVALID_OPERATION: 'invalid_operation',
  MAX_FILES_SIZE_EXCEEDED: 'max_file_sizes_exceeded',
  CANT_REPUBLISH_COURSE_WITH_ENROLLMENTS: 'cant_republish_course_if_not_admin',
  CANT_MAKE_LESSON_NON_GRADABLE: 'cant_make_lesson_non_gradable',
  ALREADY_HAVE_A_SUB: 'already_has_active_subscription',
  ALREADY_USED_TRIAL: 'already_used_trial',
  LTI_ISSUER_ALREADY_REGISTERED: 'platform_issuer_already_registered',
  MAX_AMOUNT_OF_USERS_REACHED: 'maximum_users_qty_reached',
  INCORRECT_USERS_AMOUNT: 'wrong_user_qty',
  CANT_CHANGE_TO_SAME_TIER: 'same_tier_not_allowed',
  LESS_USERS_IN_SUB_THAN_YOU_HAVE: 'less_then_current_qty',
  MAX_AMOUNT_OF_GENERATIONS_REACHED: 'no_available_ai_video_generation',
  AI_SERVER_OVRLOADED: 'server_overloaded',
  AI_CANT_READ_FILE: 'can_t_read_file',
  AI_TOO_MANY_SLIDES: 'slides_more_than_25',
  AI_VIDEO_ALREADY_BEING_GENERATED:
    'not_allowed_more_than_one_generation_at_once',
  USER_ALREADY_A_LEARNER_IN_THIS_WORKSPACE:
    'user_already_a_learner_in_this_workspace',
  NO_AUDIO_IN_VIDEO: 'no_audio_in_video',
  ALREADY_GENERATING_LESSON:
    'not_allowed_more_than_one_lesson_generation_at_once',
  MAX_AMOUNT_OF_COURSES: 'max_amount_of_courses_reached',
  INVITATION_USER_EXISTS: 'user_already_exists',
  NOT_ENOUGH_AI_CREDITS: 'not_enough_credits',
  MAX_AMOUNT_OF_LEARNERS_REACHED: 'maximum_learners_qty_reached',
  MAX_AMOUNT_OF_INSTRUCTORS_REACHED: 'maximum_instructors_qty_reached',
  MAX_AMOUNT_OF_CREATORS_REACHED: 'maximum_creators_qty_reached',
  STAFF_WAS_REMOVED: 'staff_is_not_active',
};

const internalErrorCodes = {
  INVALID_EMAIL: 'provide_email_error',
  INVALID_PASSWORD: 'provide_password_error',
  PROVIDE_USERNAME: 'provide_username_error',
  PROVIDE_VERIFICATION_CODE: 'provide_verification_code',
  SOMETHING_WENT_WRONG: 'something_went_wrong',
  NEED_AT_LEAST_ONE_CORRECT_ANSWER: 'need_at_least_one_correct_answer',
  CANT_GENERATE_QUESTIONS: 'cant_generate_questions',
  INVALID_FILE_EXTENSION: 'invalid_file_extension',
};

export {internalErrorCodes, getErrorFromCode};
