import {makeAutoObservable} from 'mobx';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class SubscriptionSettingsStore {
  private subscriptionRepo = new SubscriptionRepository();

  private loadingModule = new LoadingModule();

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public async getOrganizationSubscription(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.subscriptionRepo.getOrganizationSubscription(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getPaymentMethodSession(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.subscriptionRepo.getPaymentMethodSession(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async cancelSubscription(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.subscriptionRepo.cancelSubscription(organizationId);
    this.loadingModule.endLoading();
    return result.success;
  }

  public async getInvoicesList(organizationId: string) {
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.getInvoicesList(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data.data;
    }
    return [];
  }

  public async buySubscription(organizationId: string, tier: number) {
    if (this.loadingModule.isLoading) return;
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.getSubscribeSession(
      organizationId,
      tier,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else {
      toast.error(getErrorFromCode(''), toastConfig);
    }
  }

  public async renewSubscription(organizationId: string) {
    if (this.loadingModule.isLoading) return;
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.getRenewSession(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async upgradeTier(organizationId: string, tier: number) {
    if (this.loadingModule.isLoading) return;
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.upgradeSubscription(
      organizationId,
      tier,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else {
      toast.error(getErrorFromCode(''), toastConfig);
    }
  }
}

export default SubscriptionSettingsStore;
